import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import * as StringTool from '../tools/StringTool';
import { getImgPath } from '../tools/StringTool';
import { useNavigate } from "react-router-dom";
import { Grid, Box, Stack, Typography } from '@mui/material';
import * as LKey from '../tools/languageKey';
import * as APJHttp from '../tools/APJHttp';
import { toastActions } from '../store/reducers/ToastSlice';
import { loadingActions } from '../store/reducers/LoadingSlice';

import { paymentActions, paymentSlice } from '../store/reducers/PaymentSlice';
import { settingActions, settingObj } from '../store/reducers/SettingSlice';
import { useSelector, useDispatch } from 'react-redux';

import SessionTitle from './SessionTitle';
import CheckIdItem_OL from '../pages/ol/CheckIdItem_OL';
import PayWayItem_OL from '../pages/ol/PayWayItem_OL';
import ProductItem_OL from '../pages/ol/ProductItem_OL';
import Footer_OL from '../components/Footer_OL';



export default function Payment_OL(props) {

    const dispatch = useDispatch();
    const paymentData = useSelector(state => state.paymentData);
    const settingData = useSelector(state => state.settingData);
    const checkIdData = useSelector(state => state.checkIdData);
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        document.title = "LORD OF NAZARICK";
        document.body.style.backgroundColor = 'white';
        StringTool.updateICO('favicon_ol_apj.ico');

        APJHttp.requestProductList((data) => {
            dispatch(paymentActions.setProductList(data));
        }, (code, msg) => {
            dispatch(paymentActions.setProductList({}));
        });
    }, []);

    function handleProductTap(index) {
        dispatch(paymentActions.setSelectedProductIndex(index));
        buyTap(index);
        console.log(paymentData.productList[index])
    }

    function showLimitAlert() {
        setOpen(true);
    };

    function hideLimitAlert() {
        setOpen(false);
    };

    //点击购买
    function buyTap(index) {
        if (checkIdData.isSuccess && paymentData.roleId) {
            StringTool.trackEvent( 'ol_payment_tap_start'); //GG埋点 点击购买
            showLoading();
            APJHttp.requestPaymentUrl(
                paymentData.payWayValue,
                paymentData.roleId,
                paymentData.productList[index],
                1,
                getResultUrl(),
                (paymentUrl) => {
                    hideLoading();
                    if (!StringTool.isEmpty(paymentUrl)) {
                        StringTool.trackEvent( 'ol_payment_get_url_success'); //GG埋点 跳转支付网站页面
                        setTimeout(() => {
                            window.location.assign(paymentUrl);
                        }, 100);
                    }

                }, (code, msg) => {
                    hideLoading();
                    if (code == 15021 || code == 15019) { //订单被限制
                        showLimitAlert();
                    } else {
                        StringTool.trackEvent( 'ol_payment_get_url_fail'); //GG埋点 支付网站url请求失败
                        dispatch(toastActions.showError(msg));
                    }
                });
        }else{
            dispatch(toastActions.showError('Please verify your ID first!'));
        }
    }

    function getResultUrl() {
        return window.location.origin + '/queryResult';
    }

    function showLoading() {
        dispatch(loadingActions.showLoading());
    }

    function hideLoading() {
        dispatch(loadingActions.hideLoading());
    }

    return (
        <Stack
            sx={{
                width: 1,
                backgroundSize: 'cover',
                backgroundImage: settingData.isMobile ? `url(${getImgPath('overlord/pay_mb.png')})` : `url(${getImgPath('overlord/bg_ol_pay.jpg')})`,
                backgroundRepeat: 'no-repeat',
                alignItems: 'center',
                backgroundPosition: 'top center',
            }}>

            <Box component={'img'}
                src={getImgPath('overlord/logo_new.png')}
                sx={{
                    top: '10rem',
                    left: '40rem',
                    height: settingData.isMobile ? '70rem' : '80rem',
                    position: 'absolute',
                    zIndex: 3,
                }} />

            <Stack sx={{
                width: 1,
                overflow: 'auto',
                alignItems: 'center',
                position: 'relative',
            }}>
                <Box component={'img'}
                    src={settingData.isMobile ? getImgPath('overlord/top_ol_mb.png') : getImgPath('overlord/top_ol_pc.png')}
                    sx={{
                        width: 1,
                    }} />

                <Stack sx={{
                    width: 1,
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    alignItems: 'center',
                    position: 'relative',

                }}>
                    <Stack sx={{
                        width: settingData.isMobile ? 1 : '1300rem',
                        maxWidth: settingData.isMobile ? 1 : '1300rem',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        position: 'absolute',
                        overflow: 'hidden',
                        mt: settingData.isMobile ? 10 : 0,
                        left: settingData.isMobile ? 100 : 'auto',
                    }}>

                        <Box component={'img'}
                            src={getImgPath('overlord/girle.png')}
                            sx={{
                                width: settingData.isMobile ? '650rem' : '650rem',
                            }} />
                    </Stack>

                    <Grid container
                        spacing={1}
                        sx={{
                            width: settingData.isMobile ? 0.9 : '900rem',
                            maxWidth: settingData.isMobile ? 0.9 : '900rem',
                            pb: 2,
                            zIndex: 2,
                            mt: -4,
                        }} >

                        {/* 1. 输入ID */}
                        <Grid item xs={settingData.isMobile ? 12 : 6} sx={{
                            mt: settingData.isMobile ? 0 : 2,
                        }}>
                            <SessionTitle title='Player ID' />
                            <CheckIdItem_OL />
                        </Grid>

                        {/* 2. 支付渠道 */}
                        {/* <Grid item xs={12}>
                            <SessionTitle title={'Payment Method'} />
                        </Grid>
                        <Grid item xs={settingData.isMobile ? 6 : 3.5}>
                            <PayWayItem_OL />
                        </Grid> */}

                        {/* 3. 商品列表 */}
                        <Grid item xs={12} sx={{
                            mt: settingData.isMobile ? 0 : 4,
                        }}>
                            <SessionTitle title='Select Product' />
                        </Grid>
                        {
                            paymentData.productList.map((data, index) =>
                                <Grid key={index} item xs={settingData.isMobile ? 6 : 3}>
                                    <ProductItem_OL index={index} data={data} selectedProduct={paymentData.selectedProduct} onClick={handleProductTap} />
                                </Grid>
                            )
                        }
                    </Grid>

                    <Box component={'img'}
                        src={getImgPath('overlord/bottom_ol.png')}
                        sx={{
                            bottom: 0,
                            width: 1,
                            position: 'absolute',
                            zIndex: 1,
                        }} />
                </Stack>

                <Footer_OL />
            </Stack>


            {/* 提示框 */}
            <Dialog
                open={open}
                onClose={hideLimitAlert}>
                <Stack sx={{
                    maxWidth: '450px',
                    display: 'flex',
                    flexDirection: 'column',
                    bgcolor: '#FDEDED',
                    color: 'black',
                    p: settingData.isMobile ? 3 : 5,
                    fontSize: settingData.isMobile ? '15px' : '20px',
                    fontWeight: 'bold',
                }}>
                    Creation of the order failed. The purchase limit has been exceeded.
                </Stack>
            </Dialog>
        </Stack>

    );
}
