import * as React from 'react';
import { Box, Stack, Typography, Grid } from '@mui/material';
import { getImgPath } from '../tools/StringTool';
import { paymentActions, paymentSlice, paymentObj } from '../store/reducers/PaymentSlice';
import { useSelector, useDispatch } from 'react-redux';


export default function PayWayItem(props) {
    const paymentData = useSelector(state => state.paymentData);

    function payWayTap() {
        props.onClick(props.type);
    }

    function getName(type) {
        if ("RZ" === type) {
            return "Razer Gold";
        } else if ("PM" === type) {
            return "Payermax";
        } else if ("XS" === type) {
            return "Xsolla";
        } else {
            return "";
        }
    }

    function getImg(type) {
        if ("RZ" === type) {
            return getImgPath('rg.png');
        } else if ("PM" === type) {
            return getImgPath('payermax.png');
        } else if ("XS" === type) {
            return getImgPath('xo.png');
        } else {
            return "";
        }
    }

    return (
        <Stack>
            <Stack
                onClick={payWayTap}
                component={'div'}
                sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'row',
                    height: { xs: '45px', sm: '55px' },
                    bgcolor: props.value == props.type ? '#20295B' : '#18214B',
                    border: 1,
                    borderColor: props.value == props.type ? '#237EE1' : '#18214B',
                    color: 'white',
                    '&:hover': {
                        bgcolor: '#20295B',
                    },
                }}>

                <Box component={'img'}
                    src={getImg(props.type)}
                    sx={{
                        borderRadius: '3px',
                        height: '30px',
                        width: '50px',
                        alignSelf: 'center',
                        ml: 2.5,
                        zIndex: '1',
                    }}>
                </Box>

                <Typography color="text.main" ml={2} alignSelf={'center'} fontSize={{ xs: 13, sm: 15 }}>
                    {getName(props.type)}
                </Typography>

                <Box component={'img'}
                    src={getImgPath('sanJiao.png')}
                    sx={{
                        display: (props.value == props.type ? 'flex' : 'none'),
                        position: 'absolute',
                        width: '25px',
                        height: '25px',
                        ml: 0,
                    }}>
                </Box>
            </Stack>

            {/* icon 列表 */}
            <Grid container
                sx={{
                    display: paymentData.payWayValue == 'PM' && props.type == 'PM' ? 'flex' : 'none',
                    mt: 1,
                    maxHeight: '145px',
                    overflow: 'auto',
                }} >
                {
                    paymentData.bankList && paymentData.bankList.map((v, i) =>
                        <Grid
                            key={i}
                            item={true}
                            xs={3}
                            sm={4}>
                            <SmallIcon imgName={v} />
                        </Grid>
                    )
                }
            </Grid>
        </Stack>
    )
}

function SmallIcon(props) {
    const paymentData = useSelector(state => state.paymentData);

    return (
        <Box
            component={'img'}
            src={getImgPath(`${paymentData.selectedCountry}/${props.imgName}.png`)}
            sx={{
                height: { xs: '40px', sm: '50px' },
                mx: 0.5,
                my: 0.3,
                bgcolor: 'white',
            }} />
    );
}