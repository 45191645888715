import axios from 'axios';
import * as APJUrl from './APJUrl';
import * as StringTool from './StringTool';
import CryptoJS from 'crypto-js';
import JSEncrypt from 'jsencrypt';
import {getLocalLanguage} from './StringTool';
import { paymentObj } from '../store/reducers/PaymentSlice';


export function post(url, param, onSuccess, onFail) {
    axios.post(APJUrl.getHost() + url, param, {
        headers: {
            'X-Sign': getSign(param),
            'X-Key': StringTool.getSessionString(StringTool.AppKey),
            'X-Channel-Key': StringTool.getSessionString(StringTool.AppChannelKey),
            'X-Language': getLocalLanguage(),
            'X-Currency':  paymentObj.currency,
            'X-GameRoleId': StringTool.getSessionString(StringTool.RID),
            'X-ApjUserId': '' //暂时没用到，登录的用户id
        },
    }).then(({ data }) => {
        if (data.code === 0) { //成功
            onSuccess(data.data);
        } else {
            onFail(data.code, data.msg);
        }
    }).catch(function (error) {
        console.log(error);
        onFail(-1000, 'Request fail, try again later.');
    });
}

function getSign(param) {
    let x = StringTool.getSessionString(StringTool.AppKey) + StringTool.getSessionString(StringTool.AppChannelKey)
    if (param != null) {
        const str = JSON.stringify(param);
        if (!StringTool.isEmpty(str)) {
            x = x + CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(str));
        }
    }

    let sign = new JSEncrypt();
    sign.setPrivateKey(window.apj_x());
    let r = sign.sign(x, CryptoJS.SHA256, "sha256");
    return r;
}
