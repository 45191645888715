export const check_id_title ="check_id_title";
export const check_id_hint = "check_id_hint";
export const check_id_submit = "check_id_submit";
export const check_id_empty = "check_id_empty";
export const check_id_fail = "check_id_fail";
export const pay_way_title = "pay_way_title";
export const product_choose_title = "product_choose_title";
export const nick_name = "nick_name";
export const player_id = "player_id";
export const total_money = "total_money";
export const edit = "edit";
export const pay_bt_title = "pay_bt_title";
export const save = "save";
export const country = "country";
export const quantity = "quantity";