import * as React from 'react';
import * as StringTool from '../tools/StringTool';
import { Divider, Grid, Box, Stack, Dialog, Typography, List } from '@mui/material';
import { getImgPath } from '../tools/StringTool';
import { settingActions } from '../store/reducers/SettingSlice';
import { useSelector, useDispatch } from 'react-redux'

export default function LanguageDialog(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    function closeTap() {
        dispatch(settingActions.showLangAlert(false));
    }

    function saveTap() {
        dispatch(settingActions.saveLang());
    }

    return (
        <Dialog
            open={settingData.openLanguage}>
            <Stack
                sx={{
                    bgcolor: '#141C3D',
                    width: { xs: '280px', sm: '600px' },
                    alignItems: 'center',
                }}>

                <Box
                    onClick={closeTap}
                    component={'img'}
                    src={getImgPath('close.png')}
                    sx={{
                        height: '20px',
                        width: '20px',
                        position: 'absolute',
                        top: '15px',
                        right: '15px',
                        cursor: 'pointer',
                    }} />

                <List
                    sx={{
                        width: '100%',
                        height: '190px',
                        overflow: 'auto',
                        mt: 7,
                    }}>
                    <Grid container
                        spacing={{ xs: 1, sm: 2 }}
                        sx={{
                            px: { xs: 2, sm: 6 },
                        }}>
                        {
                            StringTool.getLanguageList().map((item, i) =>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    key={i}>
                                    <LanguageItem data={item} />
                                </Grid>
                            )
                        }
                    </Grid>
                </List>

                <Divider sx={{
                    width: 1,
                    bgcolor: '#232B48',
                    mt: { xs: 1, sm: 2 },
                }} />

                <Box
                    onClick={saveTap}
                    sx={{
                        borderRadius: '4px',
                        fontSize: 14,
                        color: 'white',
                        bgcolor: '#3A7BFC',
                        height: '40px',
                        lineHeight: '40px',
                        width: '200px',
                        textAlign: 'center',
                        cursor: 'pointer',
                        my: { xs: 2, sm: 2.5 },
                    }}>{StringTool.getLanguageDic(settingData.languageTmp)['save']}</Box>

            </Stack>

        </Dialog>
    );
}


function LanguageItem(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    function itemTap() {
        dispatch(settingActions.saveTmpLanguage(props.data.lang));
    }

    return (
        <Stack
            onClick={itemTap}
            component={'div'}
            sx={{
                display: 'flex',
                flexDirection: 'row',
                height: { xs: '40px', sm: '50px' },
                bgcolor: props.data.lang == settingData.languageTmp ? '#20295B' : '#18214B',
                border: 1,
                borderColor: props.data.lang == settingData.languageTmp ? '#237EE1' : '#18214B',
                color: 'white',
                cursor: 'pointer',
                '&:hover': {
                    bgcolor: '#20295B',
                },
            }}>

            <Typography color="text.main" ml={4} alignSelf={'center'} fontSize={14}>
                {props.data.name}
            </Typography>

            <Box component={'img'}
                src={getImgPath('sanJiao.png')}
                sx={{
                    display: props.data.lang == settingData.languageTmp ? 'flex' : 'none',
                    position: 'absolute',
                    width: '25px',
                    height: '25px',
                    ml: 0,
                }}>
            </Box>

        </Stack>
    )
}

