import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as LKey from '../tools/languageKey';
import { Box, Stack, Typography } from '@mui/material';
import { loadingActions } from '../store/reducers/LoadingSlice';
import * as APJHttp from '../tools/APJHttp';
import ToastComponent from './ToastComponent';
import { toastActions } from '../store/reducers/ToastSlice';
import { checkIdActions } from '../store/reducers/CheckIdSlice';
import { useNavigate } from "react-router-dom";
import * as StringTool from "../tools/StringTool";

export default function PayFooterItem() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const settingData = useSelector(state => state.settingData);
    const checkIdData = useSelector(state => state.checkIdData);
    const paymentData = useSelector(state => state.paymentData);

    React.useEffect(() => {
        hideLoading();
        dispatch(checkIdActions.resetInput());
    }, []);

    function handlePayTap() {
        if (checkIdData.isSuccess && paymentData.selectedProduct && paymentData.roleId) {
            StringTool.trackEvent( 'payment_tap_start'); //GG埋点 点击购买
            showLoading();
            APJHttp.requestPaymentUrl(
                paymentData.payWayValue,
                paymentData.roleId,
                paymentData.selectedProduct,
                paymentData.quantity,
                getResultUrl(),
                (paymentUrl) => {
                    hideLoading();
                    if(!StringTool.isEmpty(paymentUrl)){
                        StringTool.trackEvent( 'payment_get_url_success'); //GG埋点 跳转支付网站页面
                        setTimeout(() => {
                            window.location.assign(paymentUrl);
                        }, 100);
                    }
                    
                }, (code, msg) => {
                    StringTool.trackEvent( 'payment_get_url_fail'); //GG埋点 支付网站url请求失败
                    hideLoading();
                    dispatch(toastActions.showError(msg));
                });
        }
    }

    function getResultUrl() {
        return window.location.origin + '/queryResult';
    }

    function showLoading() {
        dispatch(loadingActions.showLoading());
    }

    function hideLoading() {
        dispatch(loadingActions.hideLoading());
    }

    return (
        <Stack
            sx={{
                bgcolor: '#0B1339',
                width: 1,
                alignItems: 'center',
                display: 'absolute',
                left: 0,
                top: 0,
            }}>
            <Stack sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                width: { xs: '100%', sm: '1000px' },
                flex: 1,
                alignItems: 'center',
                my: 2,
            }}>

                <Box
                    onClick={handlePayTap}
                    sx={{
                        cursor: 'pointer',
                        borderRadius: '4px',
                        fontWeight: 'bold',
                        color: (checkIdData.isSuccess && paymentData.selectedProduct) ? 'white' : '#7E8398',
                        bgcolor: (checkIdData.isSuccess && paymentData.selectedProduct) ? '#3A7BFC' : '#293052',
                        height: '45px',
                        lineHeight: '45px',
                        width: '150px',
                        textAlign: 'center',
                        fontSize: { xs: 13, sm: 15 },
                        mr: { xs: 2, sm: 0 },
                        ml: 6,
                    }}>
                    {settingData.languageDic[LKey.pay_bt_title]}

                </Box>

                <Box flex={{ xs: 1, sm: 0 }} />

                <Stack sx={{
                    color: 'white',
                    ml: 2,
                }}>

                    <Typography sx={{
                        fontWeight: 'bold',
                        fontSize: { xs: 14, sm: 16 },
                        fontWeight: 'bold',
                    }}>
                        {checkIdData.isSuccess && paymentData.selectedProduct && `${paymentData.selectedProduct.discountPrice * paymentData.quantity} ${paymentData.selectedProduct.currency}`}
                    </Typography>

                    <Typography
                        component={'del'}
                        sx={{
                            fontWeight: 'bold',
                            fontSize: { xs: 11, sm: 13 },
                            color: '#7E8398'
                        }}>
                        {checkIdData.isSuccess && paymentData.selectedProduct && paymentData.selectedProduct.discountPrice != paymentData.selectedProduct.origionalPrice && `${paymentData.selectedProduct.origionalPrice * paymentData.quantity} ${paymentData.selectedProduct.currency}`}
                    </Typography>
                </Stack>
            </Stack>
            <ToastComponent />
        </Stack>
    );

}