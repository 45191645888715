import { createSlice } from '@reduxjs/toolkit'

export const toastSlice = createSlice({
    name: 'toast',
    initialState: {
        msg: '',
        type: 'success',
        open: false,
    },
    reducers: {
        showSuccess: (state, action) => {
            state.msg = action.payload;
            state.type = 'success';
            state.open = true;
        },

        showInfo: (state, action) => {
            state.msg = action.payload;
            state.type = 'info';
            state.open = true;
        },

        showError: (state, action) => {
            state.msg = action.payload;
            state.type = 'error';
            state.open = true;
        },

        dismiss: (state) => {
            state.open = false;
        },
    }
})

export const toastActions = toastSlice.actions

export default toastSlice.reducer