import { createSlice } from '@reduxjs/toolkit'

export const checkIdSlice = createSlice({
    name: 'checkId',
    initialState: {
        inputValue: '',
        errorMsg: '',
        isSuccess: false,
        isLoading: false,
    },
    reducers: {
        updateInputValue: (state, action) => {
            state.inputValue = action.payload;
            state.errorMsg = '';
            state.isSuccess = false;
            state.isLoading = false;
        },

        resetInput: (state) => {
            state.errorMsg = '';
            state.inputValue = '';
            state.isSuccess = false;
            state.isLoading = false;
        },

        showLoading: (state) =>{
            state.isLoading = true;
            state.isSuccess = false;
        },

        hideLoading: (state) =>{
            state.isLoading = false;
        },

        checkError: (state,action) =>{
            state.errorMsg = action.payload;
            state.isSuccess = false;
            state.isLoading = false;
        },

        checkSuccess: (state) =>{
            state.errorMsg = '';
            state.isSuccess = true;
            state.isLoading = false;
        },
    }
})

export const checkIdActions = checkIdSlice.actions

export default checkIdSlice.reducer