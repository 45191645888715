import * as React from 'react';
import * as StringTool from '../tools/StringTool';
import { Stack, CircularProgress, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom'
import * as APJHttp from '../tools/APJHttp';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { toastActions } from '../store/reducers/ToastSlice';
import ToastComponent from '../components/ToastComponent';
import {settingObj} from '../store/reducers/SettingSlice';

export default function QueryResultPage() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = React.useState(true);
    const [msg, setMsg] = React.useState(settingObj.languageDic['lang12']);
    const [orderIdMsg, setOrderIdMsg] = React.useState();
    let orderId = null;

    React.useEffect(() => {
        orderId = searchParams.get('apj_order_id');
        if(StringTool.isEmpty(orderId)){
            orderId = searchParams.get('outTradeNo');
        }

        if (!StringTool.isEmpty(orderId)) {
            setTimeout(() => { //延迟2秒查询
                checkResult(orderId);
            }, 2000);
        } else {
            
            showError(settingObj.languageDic['lang0']);
        }
    }, []);

    function checkResult(orderId) {
        const gid = StringTool.getSessionString(StringTool.GameId);
        let preStr = "";
        if("overlord" == gid){
            preStr = "ol_";
        }


        StringTool.trackEvent( preStr + 'payment_result_check_start'); //GG埋点 开始检查购买结果
        APJHttp.checkPaymentResult(orderId, (data) => {
            StringTool.trackEvent( preStr + 'payment_result_check_success'); //GG埋点 购买成功
            const result = {
                ...data,
                orderId: orderId,
            };
            navigate('/payResultPage', { 
                state: result
            });
        }, (code, msg) => {
            StringTool.trackEvent( preStr + 'payment_result_check_fail'); //GG埋点 购买失败
            showError(msg);
        });
    }

    function showError(msg) {
        if (!StringTool.isEmpty(orderId)) {
            setOrderIdMsg(settingObj.languageDic['lang7'] + orderId);
        }

        dispatch(toastActions.showError(msg));
        setMsg(msg);
        setLoading(false);
    }

    return (
        <Stack sx={{
            position: 'absolute',
            height: 1,
            width: 1,
            justifyContent: 'center',
            alignItems: 'center',
        }}>

            <Stack sx={{
                justifyContent: 'center',
                alignItems: 'center',
            }}>

                <CircularProgress
                    sx={{
                        display: loading ? 'auto' : 'none',
                        color: '#D0CDE4',
                        mb: 5,
                    }} />

                <Typography sx={{
                    fontSize: {xs:14,sm:16},
                    color: '#D0CDE4',
                }}>
                    {msg} <br /> {orderIdMsg}
                </Typography>
            </Stack>
            <ToastComponent />
        </Stack>
    );

}

