import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import loadingSlice from './reducers/LoadingSlice';
import settingSlice from './reducers/SettingSlice';
import paymentSlice from './reducers/PaymentSlice';
import checkIdSlice from './reducers/CheckIdSlice';
import toastSlice from './reducers/ToastSlice';


const store = configureStore({
    reducer: {
        loadingData: loadingSlice,
        settingData: settingSlice,
        paymentData: paymentSlice,
        checkIdData: checkIdSlice,
        toastData: toastSlice,
    },
    middleware: [thunk]
});

export default store;