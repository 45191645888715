import * as React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { getImgPath } from '../../tools/StringTool';
import { useSelector, useDispatch } from 'react-redux';


export default function ProductItem_OL(props) {

    const settingData = useSelector(state => state.settingData);

    function onClick() {
        props.onClick(props.index);
    }

    return (
        <Stack
            onClick={onClick}
            component={'div'}
            sx={{
                cursor: 'pointer',
                display: 'flex',
                position: 'relative',
                mr: settingData.isMobile ? 0.5 : 2,
                ml: settingData.isMobile ? 0.5 : 0,
                mb: settingData.isMobile ? 0.5 : 2,
                alignItems: 'center',
            }}>

            <Box component={'img'}
                src={getImgPath(props.data.productIcon)}
                sx={{
                    width: 1,
                }}>
            </Box>

            {/* <Box component={'img'}
                src={getImgPath('overlord/selected.png')}
                sx={{
                    display: (props.selectedProduct && props.selectedProduct.productId == props.data.productId) ? 'flex' : 'none',
                    position: 'absolute',
                    alignSelf: 'start',
                    height: settingData.isMobile ? '33px' : '44px',
                }}>
            </Box> */}
        </Stack>
    );
}