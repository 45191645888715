import * as React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { getImgPath } from '../tools/StringTool';


export default function CountryItem(props) {

    function itemTap() {
        props.onClick(props.country, props.currency);
    }

    return (
        <Stack
            onClick={itemTap}
            component={'div'}
            sx={{
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'row',
                height: { xs: '45px', sm: '55px' },
                bgcolor: props.selCountry == props.country ? '#20295B' : '#18214B',
                border: 1,
                borderColor: props.selCountry == props.country ? '#237EE1' : '#18214B',
                color: 'white',
                '&:hover': {
                    bgcolor: '#20295B',
                },
            }}>

            <Box component={'img'}
                src={getImgPath(props.country.toUpperCase() + '.png')}
                sx={{
                    borderRadius: '3px',
                    height: '30px',
                    width: '50px',
                    alignSelf: 'center',
                    ml: 2.5,
                    zIndex: '1',
                }}>
            </Box>

            <Typography color="text.main" ml={2} alignSelf={'center'} fontSize={{ xs: 13, sm: 15 }}>
               {props.country}
            </Typography>

            <Box component={'img'}
                src={getImgPath('sanJiao.png')}
                sx={{
                    display: (props.selCountry == props.country ? 'flex' : 'none'),
                    position: 'absolute',
                    width: '25px',
                    height: '25px',
                    ml: 0,
                }}>
            </Box>

        </Stack>
    )
}