const Test_Host = "https://sdk-staging.aplusjapan-game.com";
const Release_Host = "https://cr-sdk-production.aplusjapan-game.com";

export function getHost(){
    if(process.env.REACT_APP_ENV === 'production'){
        return Release_Host;
    }
    return Test_Host;
}

export const CheckRoleId = "/api/gameserver/apjpaymentweb/v1/check_game_role";

export const ProductList = "/api/gameserver/apjpaymentweb/v1/product_list";

export const RazerPaymentUrl = "/api/gameserver/apjpaymentweb/v1/launch_rz_payment_flow";

export const PayermaxPaymentUrl = "/api/gameserver/apjpaymentweb/v1/launch_pm_payment_flow";

export const XsollaPaymentUrl = "/api/gameserver/apjpaymentweb/v1/launch_xsolla_payment_flow";

export const CheckPaymentResult = "/api/gameserver/apjpaymentweb/v1/check_order_info";



