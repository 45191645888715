import * as APJRequest from '../tools/APJRequest';
import * as APJUrl from '../tools/APJUrl';
import * as StringTool from './StringTool';


export function checkRoleId(gameRoleId, onSuccess, onFail) {
    const param = {
        gameRoleId: gameRoleId
    };
    APJRequest.post(APJUrl.CheckRoleId, param, onSuccess, onFail);
}

export function requestProductList(onSuccess, onFail) {
    APJRequest.post(APJUrl.ProductList, null, (data) => {
        onSuccess(data);
    }, onFail);
}

export function requestPaymentUrl(type, roleId, productObj, quantity, callbackUrl, onSuccess, onFail) {
    const param = {
        gameRoleId: roleId,
        productId: productObj.productId,
        quantity: quantity,
        currency: productObj.currency,
        paymentSuccessReturnUrl: callbackUrl,
    };
    let url = '';
    if(type === 'RZ'){
        url = APJUrl.RazerPaymentUrl;
    }else if(type === 'PM'){
        url = APJUrl.PayermaxPaymentUrl;
    }else if(type === 'XS'){
        url = APJUrl.XsollaPaymentUrl;
    }

    APJRequest.post(url, param, (data)=>{
        onSuccess(data.paymentFrontendUrl);
    }, onFail);
}

export function checkPaymentResult(orderId, onSuccess, onFail) {
    if (StringTool.isEmpty(orderId)) {
        onFail(1300, 'Order id is empty!');
        return;
    }

    const param = {
        apjOrderId: orderId
    };
    APJRequest.post(APJUrl.CheckPaymentResult, param, onSuccess, onFail);
}


