import * as React from 'react';
import { Divider,Box, Stack, Dialog,Typography} from '@mui/material';
import { getImgPath } from '../tools/StringTool';

export default function AccountHintDialog(props) {
    return (
        <Dialog
            open={props.open}>
            <Stack sx={{
                width: { xs: '300px', sm: '500px' },
                bgcolor: '#171F45',
                color: 'white',
                pb: 3,
            }}>
                <Stack direction={'row'} sx={{
                    alignItems: 'center',
                    height: '50px',
                }}>

                    <Typography flex={1} fontSize={15} ml={2}>找不到玩家ID吗?</Typography>

                    <Box
                        component={'img'}
                        src={getImgPath('close.png')}
                        onClick={props.closeTap}
                        sx={{
                            height: '20px',
                            width: '20px',
                            mr: 2,
                        }} />
                </Stack>

                <Divider sx={{ width: 1, bgcolor: '#232B48' }} />

                <Typography flex={1} fontSize={13} ml={3} mt={1.5} mb={1}>1. 进入游戏界面</Typography>
                <Box component={'img'} 
                src={getImgPath('hint0.jpg')}
                    sx={{
                        height: { xs: '150px', sm: '250px' },
                        mx: 3,
                        objectFit: 'contain',
                    }} />

                <Typography flex={1} fontSize={13} ml={3} mt={1.5} mb={1}>2. 找寻游戏ID</Typography>
                <Box component={'img'} 
                src={getImgPath('hint1.jpg')}
                    sx={{
                        height: { xs: '150px', sm: '250px' },
                        mx: 3,
                        objectFit: 'contain',
                    }} />

            </Stack>
        </Dialog>
    );
}