import { createSlice } from '@reduxjs/toolkit'

export const loadingSlice = createSlice({
    name: 'loading',
    initialState: {
        open: false,
        callback: null,
    },
    reducers: {
        showLoading: (state) => {
            state.open = true;
            state.callback = null;
        },

        showLoadingWithCallback: (state, action) => {
            state.open = true;
            state.callback = action.payload;
        },

        hideLoading: state => {
            state.open = false;
            state.callback = null;
        },

        onLoadingClick: state => {
            if (state.callback != null) {
                state.callback();
                state.open = false;
            }
        },
    }
})

export const loadingActions = loadingSlice.actions

export default loadingSlice.reducer