import * as React from 'react';
import { Typography, Stack, Box } from '@mui/material';
import AccountHintDialog from './AccountHintDialog';
import { getImgPath } from '../tools/StringTool';
import { settingActions, settingObj } from '../store/reducers/SettingSlice';
import { useSelector, useDispatch } from 'react-redux';

export default function SessionTitle(props) {

    const settingData = useSelector(state => state.settingData);
    const [open, setOpen] = React.useState(false);

    function hintTaped() {
        setOpen(true);
    }

    function closeTap() {
        setOpen(false);
    }

    return (
        <Stack
         direction={'row'}
        alignItems={'center'} 
        mt={settingData.isMobile ? 3 : 4}>
            <Typography
                fontSize={settingData.isMobile ? '35rem':  '20rem'}
                color={'white'}
                fontFamily={window.MyFontA}
                fontWeight={'bold'}>
                {props.title}
            </Typography>

            <Box component={'img'}
                src={getImgPath('accountHint.png')}
                onClick={hintTaped}
                sx={{
                    display: 'none',
                    // display: props.showHint ? 'flex' : 'none',
                    width: '16px',
                    height: '16px',
                    ml: 1,
                }}>
            </Box>

            <AccountHintDialog open={open} closeTap={closeTap} />
        </Stack>
    );
}