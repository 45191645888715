import { createSlice } from '@reduxjs/toolkit'
import * as StringTool from '../../tools/StringTool';
import { getLocalLanguage } from '../../tools/StringTool';

export const settingObj = {
    languageDic:StringTool.getLanguageDic(getLocalLanguage()),
}

export const settingSlice = createSlice({
    name: 'setting',
    initialState: {
        openLanguage: false, //语言弹框
        languageTmp: getLocalLanguage(),
        languageValue: getLocalLanguage(),
        languageDic: StringTool.getLanguageDic(getLocalLanguage()),
        innerHeight: window.innerHeight,
        isMobile: false,
    },
    reducers: {
        refreshInnerHeight: (state, action) => {
            state.innerHeight = window.innerHeight;
        },

        setIsMobile: (state, action) => {
            state.isMobile = action.payload;
        },

        showLangAlert: (state, action) => {
            state.openLanguage = action.payload;
        },

        saveTmpLanguage: (state, action) => {
            state.languageTmp = action.payload;
        },

        saveLang: (state) => {
            StringTool.saveLocalString(StringTool.Language, state.languageTmp);
            state.languageValue = state.languageTmp;
            state.openLanguage = false;
            state.languageDic = StringTool.getLanguageDic(state.languageTmp);
            settingObj.languageDic = StringTool.getLanguageDic(state.languageTmp);
        },
    }
})

export const settingActions = settingSlice.actions

export default settingSlice.reducer