import { createSlice } from '@reduxjs/toolkit'
import * as StringTool from '../../tools/StringTool';
import BankFile from '../../tools/bank.json';

export const paymentObj = {
    currency: '', //网络请求用，货币单位
    quantity: 1,
}

export const paymentSlice = createSlice({
    name: 'payment',
    initialState: {
        roleId: '',
        roleName: '',
        quantity: 1,

        payWayValue: null,
        selectedProductIndex: 0,
        selectedProduct: null,
        selectedCountry: null, // SG,MY,TH,PH,ID

        payWayList: [],
        productList: [],
        countryList: [],
        currencyList: [],  //SGD,MYR,THB,PHP,IDR
        bankList: [], //PM 支持的方式
    },
    reducers: {
        setProductList: (state, action) => {
            state.productList = action.payload.productList;
            if (state.productList != null && state.productList.length > 0) {
                state.selectedProduct = action.payload.productList[state.selectedProductIndex];

                //1. 国家有了就不更新了
                if (state.countryList.length <= 0) {
                    const countryStr = action.payload.sellingCountrys;
                    const currencyStr = action.payload.sellingCurrency;
                    state.countryList = countryStr.split(',');
                    state.currencyList = currencyStr.split(',');

                    state.selectedCountry = state.countryList[0];
                    state.bankList = getBankList(state.countryList[0]);
                    paymentObj.currency = state.currencyList[0];

                    //2.不同国家，支付方式个数不一样
                    let payWayStr = action.payload.paymentPlatform;
                    if (StringTool.isEmpty(payWayStr)) {
                        state.payWayList = StringTool.getPayWayList();
                    }else{
                        state.payWayList = payWayStr.split(',');
                    }
                    state.payWayValue = state.payWayList[0];
                }

            } else {
                state.productList = [];
                state.countryList = [];
                state.currencyList = [];

                state.selectedProduct = null;
                state.selectedCountry = null;
                paymentObj.currency = '';
                state.payWayValue = null;
            }
        },

        setSelectedProductIndex: (state, action) => {
            state.selectedProductIndex = action.payload;
            state.selectedProduct = state.productList[state.selectedProductIndex];
        },

        setSelectedCountry: (state, action) => {
            state.selectedCountry = action.payload.country;
            paymentObj.currency = action.payload.currency;
            //对应国家 PM 支持的支付方式
            state.bankList = getBankList(action.payload.country);
        },

        setPayWay: (state, action) => {
            state.payWayValue = action.payload;
        },

        setRoleIdAndName: (state, action) => {
            state.roleId = action.payload.roleId;
            state.roleName = action.payload.roleName;
            StringTool.saveSessionString(StringTool.RID, state.roleId);
        },

        resetInput: (state) => {
            state.roleId = '';
            state.roleName = '';
        },

        quantityPlus: (state) => {
            paymentObj.quantity = paymentObj.quantity + 1;
            state.quantity = paymentObj.quantity;
        },

        quantityMinus: (state) => {
            if (paymentObj.quantity > 1) {
                paymentObj.quantity = paymentObj.quantity - 1;
                state.quantity = paymentObj.quantity;
            }
        }

    }
})

function getBankList(country) {
    for (const k in BankFile) {
        if (k == country) {
            return BankFile[country];
        }
    }
    return null;
}

export const paymentActions = paymentSlice.actions

export default paymentSlice.reducer