import * as React from 'react';
import * as StringTool from '../tools/StringTool';
import { getImgPath } from '../tools/StringTool';
import SessionTitle from '../components/SessionTitle';
import CheckIdItem from '../components/CheckIdItem';
import PayWayItem from '../components/PayWayItem';
import ProductItem from '../components/ProductItem';
import BannerItem from '../components/BannerItem';
import PayFooterItem from '../components/PayFooterItem';
import CountryItem from '../components/CountryItem';

import { paymentActions, paymentSlice } from '../store/reducers/PaymentSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation, useSearchParams } from 'react-router-dom'
import { Grid, Box, Stack, Typography } from '@mui/material';
import * as LKey from '../tools/languageKey';
import * as APJHttp from '../tools/APJHttp';


export default function Payment_SFD(props) {
    const dispatch = useDispatch();
    const paymentData = useSelector(state => state.paymentData);
    const settingData = useSelector(state => state.settingData);
  
    React.useEffect(() => {
      document.title = "STREET FIGHTER: DUEL";
      StringTool.updateICO('favicon_sfd.ico');

      APJHttp.requestProductList((data) => {
        dispatch(paymentActions.setProductList(data));
        setPayWayParam();
      }, (code, msg) => {
        dispatch(paymentActions.setProductList({}));
      });
    }, []);
  
    function reloadProductList() {
      APJHttp.requestProductList((data) => {
        dispatch(paymentActions.setProductList(data));
      }, (code, msg) => {
        dispatch(paymentActions.setProductList({}));
      });
    }
  
    function setPayWayParam() {
      if (!StringTool.isEmpty(props.paymentType)) {
        if (paymentData.payWayList != null && StringTool.getPayWayList().indexOf(props.paymentType) != -1) {
          dispatch(paymentActions.setPayWay(props.paymentType));
        }
      }
    }
  
    function handlePayWayTap(type) {
      dispatch(paymentActions.setPayWay(type));
    }
  
    function handleCountryTap(country, currency) {
      dispatch(paymentActions.setSelectedCountry({
        country: country,
        currency: currency
      }));
  
      setTimeout(() => {
        reloadProductList();
      }, 100);
    }
  
    function handleProductTap(index) {
      dispatch(paymentActions.setSelectedProductIndex(index));
    }
  
    return (
      <Stack
        sx={{
          alignItems: 'center',
          userSelect: 'none',
        }}>
        <BannerItem />
  
        <Grid container
          spacing={{ xs: 1, sm: 2 }}
          sx={{
            maxWidth: '1100px',
            px: { xs: 2, sm: 5 },
            pb: 13,
          }} >
  
          {/* 1. 输入ID */}
          <Grid item xs={12} sm={6}>
            <SessionTitle title={settingData.languageDic[LKey.check_id_title]} showHint={true} />
            <CheckIdItem />
          </Grid>
  
          {/* 2. 国家选择 */}
          <Grid item xs={12}>
            <SessionTitle title={settingData.languageDic[LKey.country]} />
          </Grid>
          {
            paymentData.countryList.map((item, i) =>
              <Grid
                key={i}
                item={true}
                xs={12}
                sm={4}>
                <CountryItem country={item} currency={paymentData.currencyList[i]} selCountry={paymentData.selectedCountry} onClick={handleCountryTap} />
              </Grid>
            )
          }
  
          {/* 3. 支付渠道 */}
          <Grid item xs={12}>
            <SessionTitle title={settingData.languageDic[LKey.pay_way_title]} />
          </Grid>
          {
            paymentData.payWayList.map((item, i) =>
              <Grid
                key={i}
                item={true}
                xs={12}
                sm={4}>
                <PayWayItem type={item} value={paymentData.payWayValue} onClick={handlePayWayTap} />
              </Grid>
            )
          }
  
          {/* 4. 商品列表 */}
          <Grid item xs={12}>
            <SessionTitle title={settingData.languageDic[LKey.product_choose_title]} />
          </Grid>
          {
            paymentData.productList.map((item, i) =>
              <Grid
                item
                xs={6}
                sm={6}
                md={4}
                key={i}>
                <ProductItem index={i} data={item} selectedProduct={paymentData.selectedProduct} onClick={handleProductTap} />
              </Grid>
            )
          }
  
          {/* 5. 产品数量 */}
          <Grid item xs={12} sm={6}>
            <SessionTitle title={settingData.languageDic[LKey.quantity]} />
            <QuantityItem />
          </Grid>
  
        </Grid>
  
        <Box sx={{
          zIndex: 1000,
          color: "gray",
          position: "fixed",
          bottom: 0,
          width: 1,
        }}>
          <PayFooterItem />
        </Box>  
      </Stack>
    );
  }
  
  
  function QuantityItem() {
    const dispatch = useDispatch();
    const paymentData = useSelector(state => state.paymentData);
  
    function minusTap() {
      dispatch(paymentActions.quantityMinus());
    }
  
    function plusTap() {
      dispatch(paymentActions.quantityPlus());
    }
  
    return (
      <Stack sx={{
        width: 1,
        mt: 1.5,
        mb: 5,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}>
  
        <Box
          component={'img'}
          onClick={minusTap}
          src={getImgPath('jian.png')}
          sx={{
            width: '20px',
            height: '20px',
            px: 2,
            py: 1,
            bgcolor: '#1F2A5B',
            cursor: 'pointer',
          }}
        />
  
        <Typography sx={{
          mx: 3,
          color: 'white',
          fontSize: 26,
          textAlign: 'center',
        }}>
          {paymentData.quantity}
        </Typography>
  
        <Box
          component={'img'}
          onClick={plusTap}
          src={getImgPath('jia.png')}
          sx={{
            width: '20px',
            height: '20px',
            px: 2,
            py: 1,
            bgcolor: '#1F2A5B',
            cursor: 'pointer',
          }}
        />
  
      </Stack>
    );
  
  }