import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toastActions } from '../store/reducers/ToastSlice';
import { Snackbar, Alert } from '@mui/material';

export default function ToastComponent() {

    const dispatch = useDispatch();
    const toastData = useSelector(state => state.toastData);

    function handleClose() {
        dispatch(toastActions.dismiss());
    }

    return (
        <Snackbar open={toastData.open} autoHideDuration={3000} onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}>

            <Alert severity={toastData.type} 
            sx={{
                minWidth: '250px',
                maxWidth: '380px',
                fontSize: '16rem'
            }}>
                {toastData.msg}
            </Alert>
        </Snackbar>
    );
}