import '../App.css'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import LanguageDialog from './LanguageDialog';
import * as StringTool from '../tools/StringTool';
import { getImgPath } from '../tools/StringTool';
import * as React from 'react';
import { Box, Stack } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import { settingActions } from '../store/reducers/SettingSlice';
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom";

export default function BannerItem() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const settingData = useSelector(state => state.settingData);

    function languageSettingTap() {
        dispatch(settingActions.showLangAlert(true));
    }

    function homeTap() {
        navigate('/');
    }

    return (
        <Stack sx={{
            display: 'flex',
            flexDirection: 'column',
            width: 1,
            position: 'relative',
        }}>

            <Carousel
                dynamicHeight={false}
                autoPlay={true}
                infiniteLoop={true}
                showThumbs={false}
                showIndicators={false}
                showArrows={!StringTool.isMobile}
                showStatus={false}>

                <Box
                    component={'img'}
                    src={getImgPath('ban1.png')}
                    sx={{
                        objectFit: 'cover',
                        minHeight: '160px',
                        maxHeight: '300px',
                    }} />

                <Box
                    component={'img'}
                    src={getImgPath('ban2.png')}
                    sx={{
                        objectFit: 'cover',
                        minHeight: '160px',
                        maxHeight: '300px',
                    }} />

                <Box
                    component={'img'}
                    src={getImgPath('ban3.png')}
                    sx={{
                        objectFit: 'cover',
                        minHeight: '160px',
                        maxHeight: '300px',
                    }} />

            </Carousel>

            <Stack
                sx={{
                    background: 'linear-gradient(#00000099 , #00000000 )',
                    height: '60px',
                    width: 1,
                    position: 'absolute',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                }}>

                <Stack sx={{
                    display: 'flex',
                    width: '200px',
                    flex: 1,
                    maxWidth: '1050px',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}>

                    <Box
                        onClick={homeTap}
                        component={'img'}
                        src={getImgPath('home2.png')}
                        sx={{
                            display: 'none',
                            height: '24px',
                            ml: 3,
                            cursor: 'pointer',
                        }} />

                    <Box flex={1} />

                    <Box
                        onClick={languageSettingTap}
                        sx={{
                            height: '25px',
                            lineHeight: '25px',
                            textAlign: 'center',
                            justifySelf: 'end',
                            pl: 1.5,
                            mr: 2,
                            color: 'white',
                            fontSize: { xs: 14, sm: 16 },
                            cursor: 'pointer'
                        }}>
                        {StringTool.searchLanguageData(settingData.languageValue) && StringTool.searchLanguageData(settingData.languageValue).name}
                    </Box>
                </Stack>
            </Stack>

            {/* 底部黑色渐变 */}
            <Box
                sx={{
                    background: 'linear-gradient(#131B3D00 , #131B3D )',
                    height: '80px',
                    width: 1,
                    position: 'absolute',
                    bottom: 0,
                }} />

            <LanguageDialog />
        </Stack>
    );
}