import { useParams, useLocation, useSearchParams } from 'react-router-dom'
import { Stack, Box, Typography,Button } from '@mui/material';

export default function SecondPage({ lang }) {

    let params = useParams();
    let { state, search } = useLocation();
    let [searchParams, setSearchParams] = useSearchParams();

    console.log(params);
    console.log(lang);
    console.log(state);
    console.log(search);
    console.log(searchParams.get('id'));
    
    function click0(){
        window.close();
        console.log('fff ' + window.history.length);
    }

    return (
        <Stack>
            <Button
       
             onClick={click0}>
                clicke
            </Button>
        </Stack>
    )

}