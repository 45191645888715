import * as React from 'react';
import { Stack, CircularProgress } from '@mui/material';

export default function LoadingPage() {
    return (
        <Stack sx={{
            position: 'absolute',
            bgcolor: 'white',
            height: 1,
            width: 1,
            justifyContent: 'center',
            alignItems: 'center',
        }}>

            <CircularProgress sx={{
                color: 'gray',
            }} />
        </Stack>
    );

}