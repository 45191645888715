import * as React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { getImgPath } from '../tools/StringTool';


export default function ProductItem(props) {

    function onClick() {
        props.onClick(props.index);
    }

    return (
        <Stack
            onClick={onClick}
            component={'div'}
            sx={{
                cursor: 'pointer',
                display: 'flex',
                position: 'relative',
                flexDirection: 'row',
                bgcolor: (props.selectedProduct && props.selectedProduct.productId == props.data.productId) ? '#20295B' : '#18214B',
                border: 1,
                borderColor: (props.selectedProduct && props.selectedProduct.productId == props.data.productId) ? '#237EE1' : '#18214B',
                p: 0,
                '&:hover': {
                    bgcolor: '#20295B',
                },
            }}>

            <Box component={'img'}
                src={getImgPath('sanJiao.png')}
                sx={{
                    display: (props.selectedProduct && props.selectedProduct.productId == props.data.productId) ? 'flex' : 'none',
                    position: 'absolute',
                    alignSelf: 'start',
                    width: '25px',
                    height: '25px',
                }}>
            </Box>

            <Stack sx={{
                direction: 'column',
                flexGrow: 1,
                ml: 2.2,
            }}>

                <Typography
                    sx={{
                        display: 'flex',
                        width: 0.8,
                        color: 'white',
                        fontSize: {xs:12, sm:14},
                        fontWeight: 'bold',
                        mt: 1,

                    }}>
                    {props.data.discountPrice} {props.data.currency}
                </Typography>

                <Typography
                    component={'del'}
                    sx={{
                        display: 'flex',
                        width: 0.8,
                        color: '#7E8398',
                        fontSize: {xs:11, sm:13},
                    }}>
                    {props.data.discountPrice != props.data.origionalPrice && `${props.data.origionalPrice} ${props.data.currency}`}
                </Typography>

                <Typography
                    sx={{
                        color: 'white',
                        fontSize: {xs:11, sm:13},
                        mb: 1,
                        mt: 0.5,
                    }}>
                    {props.data.productName}
                </Typography>


            </Stack>

            <Box
                component="img"
                sx={{
                    height: {xs:38, sm:60},
                    width: {xs:38, sm:60},
                    mt: 1,
                    mr:1,
                }}
                src={props.data.productIcon}
            />
        </Stack>
    );
}