import * as React from 'react';
import * as StringTool from '../tools/StringTool';
import { Box, Stack, CircularProgress, Typography } from '@mui/material';
import { checkIdActions } from '../store/reducers/CheckIdSlice';
import { paymentActions } from '../store/reducers/PaymentSlice';
import { useSelector, useDispatch } from 'react-redux'
import * as LKey from '../tools/languageKey';
import * as APJHttp from '../tools/APJHttp';
import { getImgPath } from '../tools/StringTool';

export default function CheckIdItem() {

    const dispatch = useDispatch();
    const checkIdData = useSelector(state => state.checkIdData);
    const settingData = useSelector(state => state.settingData);

    React.useEffect(()=>{
        StringTool.saveSessionString(StringTool.RID,"");  //置空
    },[]);

    function onBlur() {
        if (checkIdData.isLoading || checkIdData.isSuccess) {
            return;
        }

        if (StringTool.isEmpty(checkIdData.inputValue)) {
            checkError(settingData.languageDic[LKey.check_id_empty]);
        } else {
            handleCheckId();
        }
    }

    function onChange(e) {
        if (checkIdData.isLoading || checkIdData.isSuccess) {
            return;
        }
        let idStr = e.target.value;
        if(!StringTool.isEmpty(idStr)){ 
            idStr = idStr.trim();//去掉空格
        }
        dispatch(checkIdActions.updateInputValue(idStr));
    }

    function onResetInput() {
        dispatch(checkIdActions.resetInput());
        dispatch(paymentActions.resetInput());
    }

    function handleCheckId() {
        if (checkIdData.isLoading || checkIdData.isSuccess) {
            return;
        }
        dispatch(checkIdActions.showLoading());

        if (StringTool.isEmpty(checkIdData.inputValue)) {
            checkError(settingData.languageDic[LKey.check_id_empty]);
        } else {
            APJHttp.checkRoleId(checkIdData.inputValue, (data) => {
                checkSuccess(data.gameRoleName);
            }, (code, msg) => {
                checkError(settingData.languageDic[LKey.check_id_fail]);
            })
        }
    }

    function checkError(msg) {
        dispatch(checkIdActions.checkError(msg));
    }

    function checkSuccess(name) {
        dispatch(checkIdActions.checkSuccess());
        dispatch(paymentActions.setRoleIdAndName({
            roleId: checkIdData.inputValue,
            roleName: name,
        }));
    }

    return (
        <Stack mt={{ xs: 1, sm: 2 }}>
            <CheckIdInput
                inputValue={checkIdData.inputValue}
                onBlur={onBlur}
                onChange={onChange}
                handleCheckId={handleCheckId}
                isLoading={checkIdData.isLoading}
                bgcolor={StringTool.isEmpty(checkIdData.errorMsg) ? '#1A234D' : '#332240'}
                display={checkIdData.isSuccess && 'none'} />

            <CheckIdResult
                onResetInput={onResetInput}
                display={!checkIdData.isSuccess && 'none'} />

            <Typography
                sx={{ color: '#E15D61', mt: 1 }}
                component="span"
                fontSize={12}>
                {checkIdData.errorMsg}
            </Typography>

        </Stack>
    );
}

function CheckIdInput(props) {
    const settingData = useSelector(state => state.settingData);

    function onKeyDown(e) {
        if (e.keyCode === 13) { //enter press
            props.handleCheckId();
        }
    }

    return (
        <Stack direction="row"
            sx={{
                display: `${props.display}`,
                color: 'white',
                fontSize: 15,
            }}>

            <Stack direction={'row'} sx={{
                bgcolor: `${props.bgcolor}`,
                flexGrow: 1,
                height: '45px',
                border: 0.5,
                borderColor: '#363D66',
            }}>

                <Stack
                    component={'input'}
                    placeholder={settingData.languageDic[LKey.check_id_hint]}
                    value={props.inputValue}
                    onBlur={props.onBlur}
                    onChange={props.onChange}
                    onKeyDown={onKeyDown}
                    sx={{
                        flexGrow: 1,
                        bgcolor: 'transparent',
                        px: 2,
                        border: 0,
                        outline: 'none',
                        color: 'white',
                        '&::placeholder': {
                            color: "#A8AFD1"
                        },
                    }}>
                </Stack>

                <Box
                    sx={{
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '60px',
                        bgcolor: '#3A7BFC',
                    }} onClick={props.handleCheckId}>
                    <Box
                        sx={{
                            fontSize: 14,
                            display: `${props.isLoading && 'none'}`,
                        }} >
                        {settingData.languageDic[LKey.check_id_submit]}
                    </Box>
                    <CircularProgress
                        size={20}
                        sx={{
                            display: `${!props.isLoading && 'none'}`,
                            color: 'white',
                        }} />
                </Box>
            </Stack>


        </Stack>

    );
}

function CheckIdResult(props) {

    const paymentData = useSelector(state => state.paymentData);
    const settingData = useSelector(state => state.settingData);

    return (
        <Stack
            direction={'row'}
            sx={{
                display: `${props.display}`,
                height: '60px',
                bgcolor: '#182655',
                color: 'white',
            }}>

            <Stack direction={'column'} ml={2} justifyContent={'center'}>
                <Typography fontSize={13} color={'#A8AFD1'}>
                    {settingData.languageDic[LKey.player_id]}
                </Typography>

                <Typography fontSize={13} color={'#A8AFD1'}>
                    {settingData.languageDic[LKey.nick_name]}
                </Typography>
            </Stack>

            <Stack direction={'column'} ml={2} justifyContent={'center'} flex={1}>
                <Typography fontSize={13}>
                    {paymentData.roleId}
                </Typography>
                <Typography fontSize={13}>
                    {paymentData.roleName}
                </Typography>
            </Stack>

            <Stack direction={'row'} alignItems={'center'} onClick={props.onResetInput} 
            sx={{cursor: 'pointer'}}>
                <Typography fontSize={13} pl={3} color={'#2F5ECF'}>
                    {settingData.languageDic[LKey.edit]}
                </Typography>
                <Box component={'img'} 
                src={getImgPath('rightArrow.png')} width={15} height={15} mr={2} />
            </Stack>

        </Stack>
    );
}