import { useSelector, useDispatch } from 'react-redux';
import { Backdrop, CircularProgress } from '@mui/material';
import { loadingActions } from '../store/reducers/LoadingSlice';

export default function LoadingComponent() {
    const dispatch = useDispatch();
    const loadingData = useSelector(state => state.loadingData);

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loadingData.open}
            onClick={() => { dispatch(loadingActions.onLoadingClick()) }}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}